.custombutton {
    background-color: grey !important;
    width: 100%;
    max-width: 260px;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    float: right;
}

.btn:hover {
    color: white !important;
}

.custombutton span{
    display: inline-block;
    margin: 8px 10px 0 7px;
}
.ajuste{
    float: left !important;
    background-color: grey !important;
}